import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Props } from '../@types';
import { AuthContextType, User } from '../@types/auth';
import { decodePayload } from '../utils/decode-payload';
import { del, get, set } from 'idb-keyval';

const KEYCLOAK_ENDPOINT = (window as any).OAUTH2_PROVIDER_REFRESH_ENDPOINT;
const AUTH_COOKIE_DOMAIN = (window as any).AUTH_COOKIE_DOMAIN;

const DefaultUser: User = {
    accessToken: '',
    email: '',
    exp: 0,
    hasRole(): boolean {
        return false;
    },
    isAccessExpired(): boolean {
        return true;
    },
    keycloakUserId: '',
    name: '',
    refreshToken: '',
    roles: [],
    username: '',
    workplace: '',
};

const AuthContext = createContext<AuthContextType>({
    user: DefaultUser,
    isAuthenticated: false,
    isAuthenticationInitialized: false,
    signIn: (authToken: string, refreshToken: string) => console.log('signIn'),
    signOut: () => console.log('signOut'),
    setAuthToken: (token: string) => console.log('setToken'),
    setRefreshToken: (token: string) => console.log('setToken'),
});

const useProvideAuth = () => {
    const [user, setUser] = useState<User>(DefaultUser);
    const [isAuthenticationInitialized, setIsAuthenticationInitialized] = useState<boolean>(false);

    const getAccessToken = async () => {
        return await get<string>('access-token');
    };

    const getRefreshToken = async () => {
        return await get<string>('refresh-token');
    };

    const signOut = async () => {
        setUser(DefaultUser);
        await del('access-token');
        await del('refresh-token');
        await del('schedule-data');
        await del('line-stops');
        window.location.assign('/login');
    };

    const setAccessToken = async (token: string) => {
        await set('access-token', token);
        const updatedUser = Object.assign({}, user);
        updatedUser.accessToken = token;
        setUser(updatedUser);
    };

    const setRefreshToken = async (token: string) => {
        await set('refresh-token', token);
        const updatedUser = Object.assign({}, user);
        updatedUser.refreshToken = token;
        setUser(updatedUser);
    };

    const setCookie = (cname: string, cvalue: string, expirationDateNumber: number) => {
        const expireDate = new Date(expirationDateNumber * 1000);
        document.cookie = `${cname}=${cvalue};expires=${expireDate.toUTCString()};domain=${AUTH_COOKIE_DOMAIN};path=/`;
    };

    const signIn = async (accessToken: string, refreshToken: string) => {
        try {
            const payload = decodePayload(accessToken);

            if (!payload) {
                console.error('no payload');
                return;
            }

            const { exp, preferred_username, family_name, given_name, email, realm_access, workplace, sub } = payload;
            await setAccessToken(accessToken);
            await setRefreshToken(refreshToken);

            const resolvedUser = {
                keycloakUserId: sub,
                username: preferred_username,
                name: `${family_name} ${given_name}`,
                email,
                roles: realm_access.roles,
                accessToken,
                refreshToken,
                exp,
                workplace,
                hasRole: (role: string) => realm_access.roles.some(r => r === role),
                isAccessExpired: () => new Date().getTime() < exp,
            };

            setUser(resolvedUser);
            setCookie('authorization', accessToken, exp);
        } catch (e) {
            console.error(e);
        }
    };

    const refreshTheToken = async () => {
        if (!KEYCLOAK_ENDPOINT) {
            return;
        }
        const response = await fetch(KEYCLOAK_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                client_id: 'buszrent-crm',
                refresh_token: (await getRefreshToken()) || '',
                grant_type: 'refres_token',
            }),
        });
        const body = await response.json();
        setAccessToken(body.access_token);
        setRefreshToken(body.refresh_token);
    };

    useEffect(() => {
        const init = async () => {
            const accessToken = await getAccessToken();
            const refreshToken = (await getRefreshToken()) || '';
            if (accessToken) {
                await signIn(accessToken, refreshToken);
            }
            setIsAuthenticationInitialized(true);
        };
        init();
    }, []);

    return {
        user,
        isAuthenticated: user !== undefined && !!user.accessToken,
        isAuthenticationInitialized,
        signIn,
        signOut,
        setAuthToken: setAccessToken,
        setRefreshToken,
    };
};

const useAuth = () => {
    return useContext(AuthContext);
};

const ProvideAuth = ({ children }: Props) => {
    const auth = useProvideAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const accessToken = searchParams.get('t');
    const refreshToken = searchParams.get('r') || '';

    useEffect(() => {
        const initAuth = async () => {
            if (accessToken && auth.signIn && typeof auth.signIn === 'function') {
                await auth.setAuthToken(accessToken);
                await auth.setRefreshToken(refreshToken);
                await auth.signIn(accessToken, refreshToken);
                setSearchParams();
                return;
            }

            const storedAccessToken = await get('access-token');
            const storedRefreshToken = await get('refresh-token');
            await auth.signIn(storedAccessToken, storedRefreshToken);
        };
        initAuth();
    }, []);

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { useAuth, useProvideAuth, ProvideAuth };
