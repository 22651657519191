import React, { lazy, Suspense } from 'react';
import { Box, styled } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ProvideAuth } from '../hooks';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import { ProvideSettings } from '../hooks/useSettings';
import { ProvideScheduleSearch } from '../hooks/useScheduleSearch';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ProvideGeolocation } from '../hooks/useGeolocation';
import { hu } from 'date-fns/locale/hu';
import LoadingSpinner from './Layout/LoadingSpinner';

const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage/NotFoundPage'));
const Dashboard = lazy(() => import('./DashboardPage/DashboardPage'));
const Settings = lazy(() => import('./SettingsPage/SettingsPage'));
const Messages = lazy(() => import('./MessagesPage/MessagesPage'));
const LicenseNumber = lazy(() => import('./LicenseNumberPage/LicenseNumberPage'));

const StyledApp = styled(Box)`
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
            <StyledApp>
                <BrowserRouter>
                    <Suspense fallback={<LoadingSpinner />}>
                        <ProvideAuth>
                            <ProvideSettings>
                                <ProvideGeolocation>
                                    <ProvideSnackBar>
                                        <ProvideScheduleSearch>
                                            <Routes>
                                                <Route path='/dashboard' element={<Dashboard />} />
                                                <Route path='/settings' element={<Settings />} />
                                                <Route path='/messages' element={<Messages />} />
                                                <Route path='/' element={<Navigate to={'/login'} />} />
                                                <Route path='/login' element={<LoginPage />} />
                                                <Route path='/license-number' element={<LicenseNumber />} />
                                                <Route path='*' element={<NotFoundPage />} />
                                            </Routes>
                                        </ProvideScheduleSearch>
                                    </ProvideSnackBar>
                                </ProvideGeolocation>
                            </ProvideSettings>
                        </ProvideAuth>
                    </Suspense>
                </BrowserRouter>
            </StyledApp>
        </LocalizationProvider>
    );
}

export default App;
